import React, {useState} from "react"

import Layout from "../components/layout"
import {Formik} from "formik";
import {graphql, Link, navigate} from "gatsby"
import Img from "gatsby-image"
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {userPool} from "../utils/cognito-utils";
import SEO from "../components/seo";

const RegisterPage = ({data: {datoCmsRegisterpage: p}}) => {
  const [registrationType, setRegistrationType] = useState(null)

  const RegistrationType = ({image, label, description, registrationType}) => <div
    onClick={() => setRegistrationType(registrationType)} className="flex-1 cursor-pointer flex flex-col mx-2 lg:mx-16">
    <Img fluid={image.fluid}/>
    <div
      className="bg-yellow-700 font-title tracking-wide text-white px-4 py-2 border-white my-2 text-center">{label}</div>
    <div className="font-sans font-light">{description}</div>
  </div>

  const RegistrationTypeChoice = () => <div className="flex justify-center mb-16 mt-8 md:mt-32">
    <RegistrationType
      image={p.customerimage}
      label='Customer registration'
      description={p.customerdescription}
      registrationType='customer'
    />
    <RegistrationType
      image={p.stylistimage}
      label='Stylist registration'
      description={p.stylistdescription}
      registrationType='stylist'
    />
    <RegistrationType
      image={p.partnerimage}
      label='Partner registration'
      description={p.partnerdescription}
      registrationType='partner'
    />
  </div>

  const RegistrationForm = () => <>
    <Formik
      initialValues={{
        email: '',
        password: '',
        given_name: '',
        family_name: '',
      }}
      validateOnBlur={false}
      validate={values => {
        let errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if (!values.password) {
          errors.password = 'Required';
        }
        if (!values.given_name) {
          errors.given_name = 'Required';
        }
        if (!values.family_name) {
          errors.family_name = 'Required';
        }
        return errors;
      }}
      onSubmit={async (input, {setSubmitting, setStatus}) => {
        const {email, password, given_name, family_name} = input
        userPool.signUp(
          email,
          password,
          [
            new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'given_name', Value: given_name}),
            new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'family_name', Value: family_name}),
            new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'custom:group', Value: registrationType}),
          ],
          null,
          (err, result) => {
            if (err) {
              setStatus({msg: err.message});
            } else {
              console.log(result)
              navigate('/home')
            }
          });
        setSubmitting(false);
      }}
    >
      {({
          values,
          errors,
          touched,
          status,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <form className='w-full max-w flex flex-col items-center content-center pb-2 px-2 md:px-32 lg:px-64 mb-2'
              onSubmit={handleSubmit}>
          <div
            className="font-title text-xl uppercase mt-16 mb-6 text-center tracking-wide">{'Create ' + registrationType + ' account'}</div>
          <div className="mb-4 w-full">
            <input
              className='focus:outline-none w-full border-solid border-2 border-black p-2'
              type="text"
              name="given_name"
              placeholder='First name'
              autoFocus={true}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.given_name}
            />
            <p className="text-red-700 text-xs italic">
              {errors.given_name && touched.given_name && errors.given_name}
            </p>
          </div>
          <div className="mb-4 w-full">
            <input
              className='focus:outline-none w-full border-solid border-2 border-black p-2'
              type="text"
              name="family_name"
              placeholder='Last name'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.family_name}
            />
            <p className="text-red-700 text-xs italic">
              {errors.family_name && touched.family_name && errors.family_name}
            </p>
          </div>
          <div className="mb-4 w-full">
            <input
              className='focus:outline-none w-full border-solid border-2 border-black p-2'
              type="email"
              name="email"
              placeholder='Email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <p className="text-red-700 text-xs italic">
              {errors.email && touched.email && errors.email}
            </p>
          </div>
          <div className="mb-4 w-full">
            <input
              className='focus:outline-none w-full border-solid border-2 border-black p-2'
              type="password"
              name="password"
              placeholder='Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <p className="text-red-700 text-xs italic">
              {errors.password && touched.password && errors.password}
            </p>
          </div>
          <p className="text-red-700 text-xs italic">
            {status && status.msg}
          </p>
          <button
            className='w-full bg-black font-title tracking-wide text-white py-3'
            type="submit" disabled={isSubmitting}>
            Create account
          </button>
        </form>
      )}
    </Formik>
    <div className={'flex justify-center'}>
      <Link to='/login/' className='font-sans font-light'>Log in</Link>
    </div>
    <div className={'flex justify-center mb-32'}>
      <button className='font-sans font-light' onClick={() => setRegistrationType(null)}>Change type</button>
    </div>
  </>


  return (
    <Layout>
      <SEO title="Register" keywords={[`gatsby`, `application`, `react`]}/>
      {!registrationType && <RegistrationTypeChoice/>}
      {registrationType && <RegistrationForm/>}
    </Layout>
  );
}


export default RegisterPage

export const query = graphql`{
    datoCmsRegisterpage {
        customerimage {
            fluid(maxWidth: 800, imgixParams: {fit: "crop", w:"800", h:"500", crop:"top"}) {
                ...GatsbyDatoCmsFluid
            }
        }
        customerdescription
        stylistimage {
            fluid(maxWidth: 800, imgixParams: {fit: "crop", w:"800", h:"500", crop:"top"}) {
                ...GatsbyDatoCmsFluid
            }
        }
        stylistdescription
        partnerimage {
            fluid(maxWidth: 800, imgixParams: {fit: "crop", w:"800", h:"500", crop:"top"}) {
                ...GatsbyDatoCmsFluid
            }
        }
        partnerdescription
    }
}
`
